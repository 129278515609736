:root{
    --blue-navy: #213940;
    --light-blue: #1eafd6;
    --white: #Fafafa
}

.interlinea{
    text-align: center;
    text-decoration: underline;
    text-transform: uppercase;
}

.important{
    background-color: var(--light-blue);
    font-weight: 800;
}

.fatture-cash{
    display: flex;
    justify-content: center;
}


/* cashflow table */

.cashflow-table td, .cashflow-table th{
    min-width: 150px;
    text-align: left;
}
.cashflow-table td:nth-child(2), .cashflow-table th:nth-child(2){
    min-width: 80px;
    text-align: left;
}
.cashflow-table td:nth-child(4), .cashflow-table th:nth-child(4){
    min-width: 120px;
    text-align: left;
}
.cashflow-table td:nth-child(3), .cashflow-table th:nth-child(3){
    min-width: 120px;
    text-align: left;
}

.cashflow-table td:nth-child(5), .cashflow-table th:nth-child(5){
    min-width: 150px;
    text-align: left;
}
.cashflow-table td:nth-child(6), .cashflow-table th:nth-child(6){
    min-width: 120px;
    text-align: left;
}

.cashflow-table td:nth-child(7), .cashflow-table th:nth-child(7){
    min-width: 150px;
    text-align: left;
}

.cashflow-table td:nth-child(1), .cashflow-table th:nth-child(1){
    min-width: 80px;
    text-align: left;
}
.cashflow-table tbody tr{
    border-bottom: 1px solid black;
}




.slider-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
}

.slider-container {
    position: relative;
    width: 60%;
    margin-top: 10px;
    
}

.slider {
    width: 100%;
    appearance: none;
    height: 1px;
    background: lightgrey;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
    border: none;
    
}

.slider::-webkit-slider-thumb {
appearance: none;
width: 20px;
height: 20px;
border-radius: 50%;
background: var(--blue-navy);
cursor: pointer;

}

.slider-thumb {
display: flex;
justify-content: center;
align-items: center;
color: var(--blue-navy);

}
  


/* fatture */

.checked{
    background-color: rgba(29, 181, 70, .7);
    color: black;
}

.input-cash{
    cursor: pointer;
}

/* importi table */
.importi-title{
    margin-top: 10px;
    margin-left: 2%;
}
.importi-table{
    width: 90%;
    margin-left: 2%;
    margin-top: 30px;
}

.importi-table thead tr{
    
    border-bottom: 1px solid black;
}

.importi-table thead th{
    padding-bottom: 5px;
    padding-left: 0;
    
}

.importi-table th{
    text-align: start;
}
.importi-table td{
    text-align: start;
}

.importi-table tr{
    font-size: 18px;
}

.importi-table th:nth-child(2){
    width: 250px;
}

/* calendario */

.calendar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9000; /* Valore alto per essere sopra tutto */
    

  }
  
  .calendar-container {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    z-index: 1001; /* Posizionato sopra l'overlay */
    position: relative;

  }
  