:root{
    --blue-navy: #213940;
    --light-blue: #1eafd6;
    --white: #Fafafa
}

/* scroll */
.scroll{
    overflow-x: auto;
    white-space: nowrap;
    margin-top: 2vh;
    margin-left: 2%;
    margin-right: 2%;
    border-radius: 10px;
    
}

.scroll-table{
    border: 2px solid var(--blue-navy);
}

table{
    margin-bottom: 3vh;
    
}


/* table */
th, td{
    padding-left: 5px;
}

td{
    padding-top: 5px;
    padding-bottom: 5px;
}


table{
    border-collapse: collapse;
}
.block{
    position: sticky;
    left: 0;   
    z-index: 1;
    
}

.sticky-header {
    position: sticky;
    top: 0;
    z-index: 1; 
  }

.first-column{
    min-width: 250px;
    text-align: justify;
    
}

.columnNew{
    text-align: center;
}

.columnTipo{
    text-align: center;
}

td.first-column{
    background-color: white;
    padding-right: 5px;
    
}


.scroll td.first-column {
    position: sticky;
    left: 0;
    z-index: 3;
}

.scroll td.first-column::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0; /* Estendi il bordo per tutta l'altezza della cella */
    left: -1px; /* Posiziona il bordo sinistro */
    width: 2px; /* Larghezza del bordo sinistro */
    background-color: var(--blue-navy); /* Colore del bordo */
    z-index: 1; /* Imposta uno z-index inferiore rispetto alla cella */
}

.scroll td.first-column::after {
    content: '';
    position: absolute;
    top: 0;
    right: -2px; 
    height: 100%;
    width: 2px; 
    background-color: var(--blue-navy);
    z-index: 2;
}


th.first-column{
    background-color: var(--blue-navy);
    color: var(--white);
    padding-right: 5px;

}
th.columnNew{
    background-color: var(--blue-navy);
    color: var(--white);
    padding: 3px 0px;
}

th.columnTipo{
    background-color: var(--blue-navy);
    color: var(--white);
    padding: 3px 0px;
    width: 80px;
}

.column{
    min-width: 150px;
    text-align: center;
    padding-left: 0;
}

.columnTipoInner{
    width: 80px;
    text-align: center;
    padding-left: 0;
}

.intestazione{
    font-size: 22px;
    height: 40px;
    background-color: var(--light-blue);
    border-radius: 10px 10px 0px 0px;
}
td.last{
    background-color: lightgrey;
}

td.totali{
    background-color: var(--light-blue);
    
}




/* selection */
.radio-selection{
    margin-top: 5vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.radio-selection label{
    padding: 0 10px;
    cursor: pointer;
}



.radio-btn{
    margin-left: 2%;
}


.select-anni{
    
    display: flex;
    align-items: center;
    font-size: 18px;
    position: relative;
}

.select-anni p{
    font-size: 20px;
}

.year-selection{
    width: fit-content;
    border: 1px solid rgba(0,0,0,.5);
    border-radius: 5px;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 7px 15px;
    transition: all .3s linear;
}

.year-selection:hover{
    color: black;
    border: 1px solid black;

    
}

.year-selection p{
    font-size: 18px;
    display: flex;
    align-items: center;
    color: rgba(0,0,0,.5);
    user-select: none;
    transition: all .2s linear;
}

.year-selection p:hover{
    color: black;
    
}





/* spazio */
.scroll td.spazio-ridotto {
    position: sticky;
    left: 0;
    z-index: 2;
}

.scroll td.spazio-ridotto::before {
    content: '';
    position: absolute;
    top: 0;
    left: -1px; /* Posiziona il bordo sinistro */
    height: 100%;
    width: 2px; /* Larghezza del bordo sinistro */
    background-color: var(--blue-navy); /* Colore del bordo */
    z-index: 1; /* Imposta uno z-index inferiore rispetto alla cella */
}

.scroll td.spazio-ridotto::after {
    content: '';
    position: absolute;
    top: 0;
    right: -2px; /* Posiziona il bordo destro */
    height: 100%;
    width: 2px; /* Larghezza del bordo destro */
    background-color: var(--blue-navy); /* Colore del bordo */
    z-index: 1; /* Imposta uno z-index inferiore rispetto alla cella */
}
