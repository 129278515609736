:root{
    --blue-navy: #213940;
    --light-blue: #1eafd6;
    --white: #Fafafa
}

/* checkbox input */
.container {
    display: block;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    
}


.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;

}


.container:hover input ~ .checkmark {
background-color: #ccc;
}


.container input:checked ~ .checkmark {
background-color: var(--blue-navy);
}


.checkmark:after {
content: "";
position: absolute;
display: none;
}

/* Show checkmark */
.container input:checked ~ .checkmark:after {
display: block;
}

/* checkmark/indicator */
.container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.container p{
    margin-left: 10px;
    width: 250px;
}




/* percent */
.percent{
    margin-top: 5vh;
}
.percent p{
    width: fit-content;
    margin-left: 5px;
}

.percent label{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

/* advise */

.advise{
    font-size: 14px;
}