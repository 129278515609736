:root{
    --blue-navy: #213940;
    --light-blue: #1eafd6;
    --white: #Fafafa
}

body{
    height: 100vh;
    width: 100vw;
}

/* menu */
.top-menu{
    height: 10vh;
    background-color: var(--blue-navy);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.top-menu h1{
    color: var(--white);
    margin-left: 2%;
}

.opacity{
    opacity: .3;
}
/* aggiungi categoria */


.aggiungi{
    background-color: var(--light-blue);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .3s linear;
    box-shadow: 0px 0px 20px rgba(0,0,0,.8);
    
}

.aggiungi:hover{
    background-color: rgba(30, 175, 214, .8);
    
}


.popup-choice{
    margin-top: 10px;
    margin-left: 10px;
}

.popup-choice select{
    border: 1px solid black;
    cursor: pointer;
}


/* slide in pulsanti */
.buttons-container {
    position: absolute;
    top: 0;
    right: -100%; /* Inizialmente posizionato fuori dalla vista a destra */
    transition: right 0.5s ease-in-out; /* Definisci la transizione */
  }
  
  .slide-in {
    right: 0; /* Posizionato nella posizione finale */
  }


/* pulsanti */


#aggiungi{
    margin-top: 20px;
    color: #44AC0E;
    border: 2px solid rgba(0,0,0,.1);
}

#aggiungi:hover{
    background-color: #44AC0E;
    color: white;
    transition: all .3s linear;
}

#neutro{
    margin-top: 20px;
    color: #000;
    border: 2px solid rgba(0,0,0,.1);
}

#neutro:hover{
    background-color: lightgrey;
    color: white;
    transition: all .3s linear;
}

#elimina{
    margin-top: 20px;
    color: #D61515;
    border: 2px solid rgba(0,0,0,.1);
}
#elimina:hover{
    background-color: #D61515;
    color: white;
    transition: all .3s linear;
}


#modifica{
    margin-top: 20px;
    color: #DA8712;
    border: 2px solid rgba(0,0,0,.1);
}

#modifica:hover{
    background-color: #DA8712;
    color: white;
    transition: all .3s linear;
}



  