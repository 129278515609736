:root{
    --blue-navy: #213940;
    --light-blue: #1eafd6;
    --white: #Fafafa
}

main{
    height: fit-content;
    overflow-x: hidden;
}
/* pulsanti */
.pulsanti-scelta{
    margin-top: 5vh;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button-selection button{
    margin: 0px 8px;
    padding: 5px 10px;
    font-size: 20px;
    cursor: pointer;
    border-radius: 5px;
    outline: none;
    border: none;
    box-shadow: 0px 0px 5px rgba(0,0,0,.3);
}

.active{
    background-color: var(--blue-navy);
    color: white;
}

.search input{
    font-size: 18px;
    padding: 3px 5px;
    border-radius: 5px;
    outline: none;
    border: 1px solid black;

}

#cerca-categoria{
    margin-left: 20px;
}

.search input:focus{
    border: 2px solid black;
    box-shadow: 0px 0px 8px rgba(0,0,0,.3);
}
/* tabella */
.container-tabella{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    
}
.tabella{
    margin-top: 2vh;
    
}

.tabella th, .tabella td{
    padding-right:20px;
}

.tabella th{
    text-align: start;
    
}

.tabella td{
    max-width: 250px;
}

.tabella thead tr{
    font-size: 20px;
}

.tr-body{
    border-bottom: 1px solid black;

}

.td-input{
    border: none;
    vertical-align: middle;
    width: 180px;
    box-sizing: border-box;
    height: 100%;
    margin: 0;
    max-height: 100%;
    font-size: 18px;
}

.td-input{
    padding-top: 0;
    padding-bottom: 0;
}

.tr-body td:nth-child(6){
    width: 220px;
}

select{
    font-size: 18px;
    max-width: 200px;
}

/* radio selection */
.selection{
    margin-top: 3vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.unit{
    cursor: pointer;
    text-decoration: underline;
}

/* pagine */

.pagine{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5%;
    margin-top: 10px;
    margin-bottom: 10px;
}


/* categoria */
.categoria-selezionata{
    display: flex;
    justify-content: space-between;
    align-items: center;
}