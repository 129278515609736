@import url(https://fonts.cdnfonts.com/css/sf-pro-display);
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'SF Pro Display', sans-serif;
}

.label-container {
  position: relative;
  z-index: 9999;
}

.label-title {
  display: none;
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  z-index: 9999;
}

.label-container:hover .label-title {
  display: block;
}

input, select{
  border-radius: 5px;
}
.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #000;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Posiziona il tooltip sopra il testo */
    left: 50%;
    margin-left: -60px; /* Usa una metà della larghezza per centrare */
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%; /* Posiziona la freccia in basso */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
:root{
  --blue-navy: #213940;
  --light-blue: #1eafd6;
  --white: #Fafafa
}

/* login */
.main-login{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.login-form{
  background-color: white;
}


/* logo */
.logo{
  width: 250px;
  height: auto;
  margin-top: 5vh;
}

.logo img{
  width: 100%;
  height: 100%;
}

/* login form */

form input, form label{
  font-size: 18px;
}

form input{
  padding: 7px 10px;
  width: 300px;
  margin-top: 5px;
  border: 1px solid var(--blue-navy);
  outline: none;
  
}

form input:focus{
  border: 2px solid var(--blue-navy);
  outline: none;
}

form label{
  color: var(--blue-navy);
}

form input[type="submit"]{
  cursor: pointer;
  background-color: var(--blue-navy);
  border: none;
  outline: none;
  color: var(--white);
  transition: all .3s linear;
  
}

form input[type="text"]:focus, form input[type="password"]:focus{
  box-shadow: 0px 0px 10px rgba(33, 57, 64, .7);
}

form input[type="submit"]:hover{
  background-color: rgba(33, 57, 64, .8);
  transition: background-color 1s linear;
}



/* copyright */

.copyright{
  margin-bottom: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyright p{
  margin-left: 5px;
}

/* errori */

.error{
  background-color: rgba(243, 9, 9, .3);
  color: #F30909;
  padding: 5px 10px;
  margin-top: 5px;
  width: 300px;
}

.nuovo-utente{
  background-color: rgba(25, 219, 22, .3);
  color: #19db16;
  padding: 5px 10px;
  margin-top: 5px;
  width: 300px;
}


.container-input{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid black;
  border-radius: 5px;
}
.container-input:focus-within{
  border: 2px solid var(--blue-navy);
  outline: none;
  box-shadow: 0px 0px 7px rgba(33, 57, 64, .5);
}
.container-input input{
  border: none;
  width: 200px;
}

.container-input input[type="text"]:focus{
  border: none;
  box-shadow: none;
}
.container-input input[type="password"]:focus{
  border: none;
  box-shadow: none;
}

.eye-icon{
  cursor: pointer;
  padding-right: 5px;
}
:root{
    --blue-navy: #213940;
    --light-blue: #1eafd6;
    --white: #Fafafa
}

body{
    width: 100%;
    height: 100vh;
}

.body-main{
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
}


/* left menu */

.menu-logo{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

.menu-logo img{
    width: 70%;
    height: auto;

}


.menu-title{
    background-color: var(--light-blue);
    padding: 10px 5px;
    cursor: pointer;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.menu-item{
    margin-top: 5vh;
    color: var(--white);
}

.menu-item ul{
    margin-top: 10px;
    list-style: none;
}

.menu-item ul li{
    margin: 5px 0px;
    padding: 10px 10px;
    margin-left: 20px;
    margin-right: 10px;
    cursor: pointer;
    transition: all .3s linear;
    border-radius: 10px;
       
}

.menu-item ul li:hover{
    background-color: rgba(255, 255, 255, .3);
}



.menu-button{
    color: var(--white);
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
}   

#subcat{
    padding-left: 50px;
    font-size: 16px;
}

.subcategories{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.menu-selected{
    background-color: rgba(255, 255, 255, .3);
}

/* divide */

.divide{
    margin-top: 3vh;
    width: 85%;
}

/* copyright */

.copy{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    color: var(--white);
    width: 100%;
    
}

/*grafico*/

.recharts-tooltip{
    background-color: white;
    border-radius: 5px;
    padding: 10px 25px 10px 15px;
    border: 1px solid rgba(0,0,0,.3);
}

.title-label{
    font-weight: 800;
    font-size: 18px;
}

.first-label{
    color: #1eafd6;
    margin-top: 7px;
}
.second-label{
    margin-top: 3px;
    color: #D5D5D6;
}
:root{
    --blue-navy: #213940;
    --light-blue: #1eafd6;
    --white: #Fafafa
}

body{
    height: 100vh;
    width: 100vw;
}

/* menu */
.top-menu{
    height: 10vh;
    background-color: var(--blue-navy);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.top-menu h1{
    color: var(--white);
    margin-left: 2%;
}

.opacity{
    opacity: .3;
}
/* aggiungi categoria */


.aggiungi{
    background-color: var(--light-blue);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .3s linear;
    box-shadow: 0px 0px 20px rgba(0,0,0,.8);
    
}

.aggiungi:hover{
    background-color: rgba(30, 175, 214, .8);
    
}


.popup-choice{
    margin-top: 10px;
    margin-left: 10px;
}

.popup-choice select{
    border: 1px solid black;
    cursor: pointer;
}


/* slide in pulsanti */
.buttons-container {
    position: absolute;
    top: 0;
    right: -100%; /* Inizialmente posizionato fuori dalla vista a destra */
    transition: right 0.5s ease-in-out; /* Definisci la transizione */
  }
  
  .slide-in {
    right: 0; /* Posizionato nella posizione finale */
  }


/* pulsanti */


#aggiungi{
    margin-top: 20px;
    color: #44AC0E;
    border: 2px solid rgba(0,0,0,.1);
}

#aggiungi:hover{
    background-color: #44AC0E;
    color: white;
    transition: all .3s linear;
}

#neutro{
    margin-top: 20px;
    color: #000;
    border: 2px solid rgba(0,0,0,.1);
}

#neutro:hover{
    background-color: lightgrey;
    color: white;
    transition: all .3s linear;
}

#elimina{
    margin-top: 20px;
    color: #D61515;
    border: 2px solid rgba(0,0,0,.1);
}
#elimina:hover{
    background-color: #D61515;
    color: white;
    transition: all .3s linear;
}


#modifica{
    margin-top: 20px;
    color: #DA8712;
    border: 2px solid rgba(0,0,0,.1);
}

#modifica:hover{
    background-color: #DA8712;
    color: white;
    transition: all .3s linear;
}



  
:root{
    --blue-navy: #213940;
    --light-blue: #1eafd6;
    --white: #Fafafa
}

/* scroll */
.scroll{
    overflow-x: auto;
    white-space: nowrap;
    margin-top: 2vh;
    margin-left: 2%;
    margin-right: 2%;
    border-radius: 10px;
    
}

.scroll-table{
    border: 2px solid var(--blue-navy);
}

table{
    margin-bottom: 3vh;
    
}


/* table */
th, td{
    padding-left: 5px;
}

td{
    padding-top: 5px;
    padding-bottom: 5px;
}


table{
    border-collapse: collapse;
}
.block{
    position: sticky;
    left: 0;   
    z-index: 1;
    
}

.sticky-header {
    position: sticky;
    top: 0;
    z-index: 1; 
  }

.first-column{
    min-width: 250px;
    text-align: justify;
    
}

.columnNew{
    text-align: center;
}

.columnTipo{
    text-align: center;
}

td.first-column{
    background-color: white;
    padding-right: 5px;
    
}


.scroll td.first-column {
    position: sticky;
    left: 0;
    z-index: 3;
}

.scroll td.first-column::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0; /* Estendi il bordo per tutta l'altezza della cella */
    left: -1px; /* Posiziona il bordo sinistro */
    width: 2px; /* Larghezza del bordo sinistro */
    background-color: var(--blue-navy); /* Colore del bordo */
    z-index: 1; /* Imposta uno z-index inferiore rispetto alla cella */
}

.scroll td.first-column::after {
    content: '';
    position: absolute;
    top: 0;
    right: -2px; 
    height: 100%;
    width: 2px; 
    background-color: var(--blue-navy);
    z-index: 2;
}


th.first-column{
    background-color: var(--blue-navy);
    color: var(--white);
    padding-right: 5px;

}
th.columnNew{
    background-color: var(--blue-navy);
    color: var(--white);
    padding: 3px 0px;
}

th.columnTipo{
    background-color: var(--blue-navy);
    color: var(--white);
    padding: 3px 0px;
    width: 80px;
}

.column{
    min-width: 150px;
    text-align: center;
    padding-left: 0;
}

.columnTipoInner{
    width: 80px;
    text-align: center;
    padding-left: 0;
}

.intestazione{
    font-size: 22px;
    height: 40px;
    background-color: var(--light-blue);
    border-radius: 10px 10px 0px 0px;
}
td.last{
    background-color: lightgrey;
}

td.totali{
    background-color: var(--light-blue);
    
}




/* selection */
.radio-selection{
    margin-top: 5vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.radio-selection label{
    padding: 0 10px;
    cursor: pointer;
}



.radio-btn{
    margin-left: 2%;
}


.select-anni{
    
    display: flex;
    align-items: center;
    font-size: 18px;
    position: relative;
}

.select-anni p{
    font-size: 20px;
}

.year-selection{
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid rgba(0,0,0,.5);
    border-radius: 5px;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 7px 15px;
    transition: all .3s linear;
}

.year-selection:hover{
    color: black;
    border: 1px solid black;

    
}

.year-selection p{
    font-size: 18px;
    display: flex;
    align-items: center;
    color: rgba(0,0,0,.5);
    -webkit-user-select: none;
            user-select: none;
    transition: all .2s linear;
}

.year-selection p:hover{
    color: black;
    
}





/* spazio */
.scroll td.spazio-ridotto {
    position: sticky;
    left: 0;
    z-index: 2;
}

.scroll td.spazio-ridotto::before {
    content: '';
    position: absolute;
    top: 0;
    left: -1px; /* Posiziona il bordo sinistro */
    height: 100%;
    width: 2px; /* Larghezza del bordo sinistro */
    background-color: var(--blue-navy); /* Colore del bordo */
    z-index: 1; /* Imposta uno z-index inferiore rispetto alla cella */
}

.scroll td.spazio-ridotto::after {
    content: '';
    position: absolute;
    top: 0;
    right: -2px; /* Posiziona il bordo destro */
    height: 100%;
    width: 2px; /* Larghezza del bordo destro */
    background-color: var(--blue-navy); /* Colore del bordo */
    z-index: 1; /* Imposta uno z-index inferiore rispetto alla cella */
}

:root{
    --blue-navy: #213940;
    --light-blue: #1eafd6;
    --white: #Fafafa
}

main{
    height: -moz-fit-content;
    height: fit-content;
    overflow-x: hidden;
}
/* pulsanti */
.pulsanti-scelta{
    margin-top: 5vh;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button-selection button{
    margin: 0px 8px;
    padding: 5px 10px;
    font-size: 20px;
    cursor: pointer;
    border-radius: 5px;
    outline: none;
    border: none;
    box-shadow: 0px 0px 5px rgba(0,0,0,.3);
}

.active{
    background-color: var(--blue-navy);
    color: white;
}

.search input{
    font-size: 18px;
    padding: 3px 5px;
    border-radius: 5px;
    outline: none;
    border: 1px solid black;

}

#cerca-categoria{
    margin-left: 20px;
}

.search input:focus{
    border: 2px solid black;
    box-shadow: 0px 0px 8px rgba(0,0,0,.3);
}
/* tabella */
.container-tabella{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    
}
.tabella{
    margin-top: 2vh;
    
}

.tabella th, .tabella td{
    padding-right:20px;
}

.tabella th{
    text-align: start;
    
}

.tabella td{
    max-width: 250px;
}

.tabella thead tr{
    font-size: 20px;
}

.tr-body{
    border-bottom: 1px solid black;

}

.td-input{
    border: none;
    vertical-align: middle;
    width: 180px;
    box-sizing: border-box;
    height: 100%;
    margin: 0;
    max-height: 100%;
    font-size: 18px;
}

.td-input{
    padding-top: 0;
    padding-bottom: 0;
}

.tr-body td:nth-child(6){
    width: 220px;
}

select{
    font-size: 18px;
    max-width: 200px;
}

/* radio selection */
.selection{
    margin-top: 3vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.unit{
    cursor: pointer;
    text-decoration: underline;
}

/* pagine */

.pagine{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5%;
    margin-top: 10px;
    margin-bottom: 10px;
}


/* categoria */
.categoria-selezionata{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
:root{
    --blue-navy: #213940;
    --light-blue: #1eafd6;
    --white: #Fafafa
}

/* checkbox input */
.container {
    display: block;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 20px;
    -webkit-user-select: none;
    user-select: none;
}


.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    
}


.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;

}


.container:hover input ~ .checkmark {
background-color: #ccc;
}


.container input:checked ~ .checkmark {
background-color: var(--blue-navy);
}


.checkmark:after {
content: "";
position: absolute;
display: none;
}

/* Show checkmark */
.container input:checked ~ .checkmark:after {
display: block;
}

/* checkmark/indicator */
.container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.container p{
    margin-left: 10px;
    width: 250px;
}




/* percent */
.percent{
    margin-top: 5vh;
}
.percent p{
    width: -moz-fit-content;
    width: fit-content;
    margin-left: 5px;
}

.percent label{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

/* advise */

.advise{
    font-size: 14px;
}
:root{
    --blue-navy: #213940;
    --light-blue: #1eafd6;
    --white: #Fafafa
}

.interlinea{
    text-align: center;
    text-decoration: underline;
    text-transform: uppercase;
}

.important{
    background-color: var(--light-blue);
    font-weight: 800;
}

.fatture-cash{
    display: flex;
    justify-content: center;
}


/* cashflow table */

.cashflow-table td, .cashflow-table th{
    min-width: 150px;
    text-align: left;
}
.cashflow-table td:nth-child(2), .cashflow-table th:nth-child(2){
    min-width: 80px;
    text-align: left;
}
.cashflow-table td:nth-child(4), .cashflow-table th:nth-child(4){
    min-width: 120px;
    text-align: left;
}
.cashflow-table td:nth-child(3), .cashflow-table th:nth-child(3){
    min-width: 120px;
    text-align: left;
}

.cashflow-table td:nth-child(5), .cashflow-table th:nth-child(5){
    min-width: 150px;
    text-align: left;
}
.cashflow-table td:nth-child(6), .cashflow-table th:nth-child(6){
    min-width: 120px;
    text-align: left;
}

.cashflow-table td:nth-child(7), .cashflow-table th:nth-child(7){
    min-width: 150px;
    text-align: left;
}

.cashflow-table td:nth-child(1), .cashflow-table th:nth-child(1){
    min-width: 80px;
    text-align: left;
}
.cashflow-table tbody tr{
    border-bottom: 1px solid black;
}




.slider-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
}

.slider-container {
    position: relative;
    width: 60%;
    margin-top: 10px;
    
}

.slider {
    width: 100%;
    -webkit-appearance: none;
            appearance: none;
    height: 1px;
    background: lightgrey;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
    border: none;
    
}

.slider::-webkit-slider-thumb {
-webkit-appearance: none;
        appearance: none;
width: 20px;
height: 20px;
border-radius: 50%;
background: var(--blue-navy);
cursor: pointer;

}

.slider-thumb {
display: flex;
justify-content: center;
align-items: center;
color: var(--blue-navy);

}
  


/* fatture */

.checked{
    background-color: rgba(29, 181, 70, .7);
    color: black;
}

.input-cash{
    cursor: pointer;
}

/* importi table */
.importi-title{
    margin-top: 10px;
    margin-left: 2%;
}
.importi-table{
    width: 90%;
    margin-left: 2%;
    margin-top: 30px;
}

.importi-table thead tr{
    
    border-bottom: 1px solid black;
}

.importi-table thead th{
    padding-bottom: 5px;
    padding-left: 0;
    
}

.importi-table th{
    text-align: start;
}
.importi-table td{
    text-align: start;
}

.importi-table tr{
    font-size: 18px;
}

.importi-table th:nth-child(2){
    width: 250px;
}

/* calendario */

.calendar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9000; /* Valore alto per essere sopra tutto */
    

  }
  
  .calendar-container {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    z-index: 1001; /* Posizionato sopra l'overlay */
    position: relative;

  }
  
.small-int{
    font-size: 12px;
}

.italic{
    font-style: italic;
    
}


