@import url('https://fonts.cdnfonts.com/css/sf-pro-display');
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'SF Pro Display', sans-serif;
}

.label-container {
  position: relative;
  z-index: 9999;
}

.label-title {
  display: none;
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  z-index: 9999;
}

.label-container:hover .label-title {
  display: block;
}

input, select{
  border-radius: 5px;
}