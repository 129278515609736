:root{
  --blue-navy: #213940;
  --light-blue: #1eafd6;
  --white: #Fafafa
}

/* login */
.main-login{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.login-form{
  background-color: white;
}


/* logo */
.logo{
  width: 250px;
  height: auto;
  margin-top: 5vh;
}

.logo img{
  width: 100%;
  height: 100%;
}

/* login form */

form input, form label{
  font-size: 18px;
}

form input{
  padding: 7px 10px;
  width: 300px;
  margin-top: 5px;
  border: 1px solid var(--blue-navy);
  outline: none;
  
}

form input:focus{
  border: 2px solid var(--blue-navy);
  outline: none;
}

form label{
  color: var(--blue-navy);
}

form input[type="submit"]{
  cursor: pointer;
  background-color: var(--blue-navy);
  border: none;
  outline: none;
  color: var(--white);
  transition: all .3s linear;
  
}

form input[type="text"]:focus, form input[type="password"]:focus{
  box-shadow: 0px 0px 10px rgba(33, 57, 64, .7);
}

form input[type="submit"]:hover{
  background-color: rgba(33, 57, 64, .8);
  transition: background-color 1s linear;
}



/* copyright */

.copyright{
  margin-bottom: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyright p{
  margin-left: 5px;
}

/* errori */

.error{
  background-color: rgba(243, 9, 9, .3);
  color: #F30909;
  padding: 5px 10px;
  margin-top: 5px;
  width: 300px;
}

.nuovo-utente{
  background-color: rgba(25, 219, 22, .3);
  color: #19db16;
  padding: 5px 10px;
  margin-top: 5px;
  width: 300px;
}


.container-input{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid black;
  border-radius: 5px;
}
.container-input:focus-within{
  border: 2px solid var(--blue-navy);
  outline: none;
  box-shadow: 0px 0px 7px rgba(33, 57, 64, .5);
}
.container-input input{
  border: none;
  width: 200px;
}

.container-input input[type="text"]:focus{
  border: none;
  box-shadow: none;
}
.container-input input[type="password"]:focus{
  border: none;
  box-shadow: none;
}

.eye-icon{
  cursor: pointer;
  padding-right: 5px;
}