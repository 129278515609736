:root{
    --blue-navy: #213940;
    --light-blue: #1eafd6;
    --white: #Fafafa
}

body{
    width: 100%;
    height: 100vh;
}

.body-main{
    height: fit-content;
    display: flex;
}


/* left menu */

.menu-logo{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

.menu-logo img{
    width: 70%;
    height: auto;

}


.menu-title{
    background-color: var(--light-blue);
    padding: 10px 5px;
    cursor: pointer;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.menu-item{
    margin-top: 5vh;
    color: var(--white);
}

.menu-item ul{
    margin-top: 10px;
    list-style: none;
}

.menu-item ul li{
    margin: 5px 0px;
    padding: 10px 10px;
    margin-left: 20px;
    margin-right: 10px;
    cursor: pointer;
    transition: all .3s linear;
    border-radius: 10px;
       
}

.menu-item ul li:hover{
    background-color: rgba(255, 255, 255, .3);
}



.menu-button{
    color: var(--white);
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
}   

#subcat{
    padding-left: 50px;
    font-size: 16px;
}

.subcategories{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.menu-selected{
    background-color: rgba(255, 255, 255, .3);
}

/* divide */

.divide{
    margin-top: 3vh;
    width: 85%;
}

/* copyright */

.copy{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    color: var(--white);
    width: 100%;
    
}

/*grafico*/

.recharts-tooltip{
    background-color: white;
    border-radius: 5px;
    padding: 10px 25px 10px 15px;
    border: 1px solid rgba(0,0,0,.3);
}

.title-label{
    font-weight: 800;
    font-size: 18px;
}

.first-label{
    color: #1eafd6;
    margin-top: 7px;
}
.second-label{
    margin-top: 3px;
    color: #D5D5D6;
}